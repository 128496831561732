export default [
  {
    name: "St. John's University",
    location: 'Queens, NY',
    degree: 'Computer Science, M.S.',
    gpa: 3.9,
    dates: '2019 - 2020',
    achievements: ['Graduate Assistant'],
  },
  {
    name: "St. John's University",
    location: 'Queens, NY',
    degree: 'Computer Science, B.S.',
    gpa: 3.98,
    dates: '2017 - 2019',
    achievements: [
      "Dean's List",
      'College of Professional Studies Honor Society',
    ],
  },
  {
    name: 'LaGuardia Community College',
    location: 'Queens, NY',
    degree: 'Computer Science, A.S.',
    gpa: 4.0,
    dates: '2015 - 2017',
    achievements: [
      "Dean's List",
      'Phi Theta Kappa Honor Society',
      'Community of Scholars',
      'Student Ambassador',
    ],
  },
];
