export default [
  {
    company: 'Fastly',
    location: 'New York, NY',
    position: 'Software Engineer',
    dates: 'Jan. 2024 - Present',
    summary: 'Building the best of the internet ⚡️'
  },
  {
    company: 'Lyft',
    location: 'New York, NY',
    position: 'Software Engineer',
    dates: 'Feb. 2021 - Apr. 2023',
    summary: 'Built tooling within the Support organization connecting Riders & Drivers with Associates and everyone in-between. FE Tech Lead of a project that reduced the number of tools needed by internal teams for content management. Reduced tooling required by 50% and saved teams ~6060 hours and $201,000 annually. Designed, specced, and executed on a new tooling architecture that enabled other lines of business to adopt, extend, and reuse our tooling to fit their business needs. This led to an increase in driver activations and generated $1.55 million in annual PMM.',
  },
  {
    company: "St. John's University",
    location: 'Queens, NY',
    position: 'Graduate Assistant',
    dates: 'Sep. 2019 - Dec. 2020',
    summary: `As a Graduate Assistant at St. John's University, Treasure primarily assisted Computer Science & Data Science professors with their research. He established Machine Learning workflows for data analysis and predictive modeling on an NVIDIA Jetson Nano GPU. He also set up a psychological experiment within Open Sesame utilizing an EEG device for the collection of brain activity data. Besides research, he also developed software for some professors. First, was an e-learning web application intended for use within courses at the university. Second, was a poly-pharmaceutical mobile application that highlighted drug-to-drug and drug-to-food interactions. He tutored Computer Science to undergraduate students, as well as assisted Data Science graduate students with their capstone project.`,
  },
  {
    company: 'Lyft',
    location: 'New York, NY',
    position: 'Software Engineer Intern',
    dates: 'Jun. 2020 - Jul. 2020',
    summary: `During this internship, Treasure got the opportunity to work as a Frontend Engineer on Lyft's Associate Tools team. The objective of his project was to migrate an internal tool from Google Scripts into the Lyft architecture for improved maintenance and scalability. Throughout the project, he refactored the application into modern React using TypeScript. He added unit tests and logs for observability. He introduced a React Context to handle complex state management within the software. He implemented ACL Roles and Policies to control user access as well as determine a user's permissions within the application. He created API endpoints in NodeJS that communicate with third-party services such as Contentful. He also deployed code, independently, in an agile development environment.`,
  },
];
