import React from "react";
import Banner from "./Banner";
import Navigation from "./Navigation";

export default () => {
  return (
    <div className="header">
      <div className="header-body">
        <Banner />
        <Navigation />
      </div>
    </div>
  );
};
