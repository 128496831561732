import React from "react";
import Biography from "./Biography";
import Contact from "./Contact";
import Resume from "./Resume";

export default () => {
  return (
    <div className="home">
      <Biography />
      <Resume />
      <Contact />
    </div>
  );
};
