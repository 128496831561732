import React from 'react';
import avatar from '../assets/avatar.jpeg';

export default () => {
  return (
    <div className='row align-items-end'>
      <div className='col'>
        <h1 className='header-title'>Treasure</h1>
        <h1 className='header-title'>Muchenagumbo</h1>
        <h6 className='header-pretitle mt-2'>Software Engineer, M.S.</h6>
      </div>
      <div className='col-auto'>
        <div className='avatar avatar-xl mr-4'>
          <img
            src={avatar}
            alt='Treasure Muchenagumbo'
            className='avatar-img rounded-circle'
          />
        </div>
      </div>
    </div>
  );
};
