import React from 'react';
import jobs from '../data/Jobs';
import Job from './Job';

export default () => {
  return (
    <div className='experience'>
      {jobs.map((job, index) => (
        <React.Fragment key={index}>
          <Job job={job} />
          {index !== jobs.length - 1 ? <hr /> : null}
        </React.Fragment>
      ))}
    </div>
  );
};
