import React from 'react';
import Resume from '../assets/resume.pdf';

export default () => {
  return (
    <p className='resume'>
      His portfolio does a pretty good job of outlining his
      professional and academic achievements; however, if you prefer, you can also view his{' '}
      <a href={Resume} target='_blank' rel='noreferrer noopener'>
        résumé.
      </a>
    </p>
  );
};
