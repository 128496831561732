import React from "react";

export default () => {
  return (
    <div className="text-center py-3">
      <hr />
      <small className="text-muted">
        &copy; {new Date().getUTCFullYear()} Treasure Muchenagumbo.
      </small>
    </div>
  );
};
