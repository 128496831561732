import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Education from './components/Education';
import Experience from './components/Experience';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './components/Home';
//import Projects from './components/Projects';

export default () => {
  // <Route path='/projects' component={Projects} />
  return (
    <div className='container'>
      <div className='row'>
        <div className='col-lg-6 offset-lg-3 col-md-8 offset-md-2'>
          <BrowserRouter>
            <Header />
            <Switch>
              <Route path='/' component={Home} exact />
              <Route path='/education' component={Education} />
              <Route path='/experience' component={Experience} />
              <Redirect to='/' />
            </Switch>
            <Footer />
          </BrowserRouter>
        </div>
      </div>
    </div>
  );
};
