import React from 'react';

const JobDetails = props => {
  return (
    <div className='job-details row'>
      <div className='col'>
        <p className='font-weight-bold mb-0'>{props.company}</p>
        <p>{props.position}</p>
      </div>
      <div className='col-auto'>
        <em className='text-muted d-block'>{props.location}</em>
        <em className='text-muted'>{props.dates}</em>
      </div>
    </div>
  );
};

export default props => {
  const { job } = props;
  return (
    <div className='job'>
      <JobDetails
        company={job.company}
        location={job.location}
        position={job.position}
        dates={job.dates}
      />
      <p>{job.summary}</p>
    </div>
  );
};
