import React from 'react';

export default () => {
  return (
    <div className='biography'>
      <p>
        Treasure is a Software Engineer at Fastly. He achieved a Master of Science degree in Computer Science from St. John's University. He is incredibly passionate about building highly performant and scalable web applications with modern tools and has done so for the past{' '}
        {new Date().getFullYear() - new Date('09/01/2019').getFullYear()} years.
      </p>
    </div>
  );
};
