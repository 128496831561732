import React from 'react';

export default () => {
  const links = [
    { href: 'mailto:contact@muchenagumbo.com', content: 'Email' },
    { href: 'https://linkedin.com/in/muchenagumbo', content: 'LinkedIn' },
    { href: 'https://github.com/muchenagumbo', content: 'GitHub' }
  ];
  return (
    <div className='contact'>
      <p>
        If you wish to contact him, you may use one, or all, of the following:
      </p>
      {links.map((link, index) => (
        <div key={index}>
          <a
            className='d-inline-block'
            href={link.href}
            target='_blank'
            rel='noopener noreferrer'
          >
            {link.content}
          </a>
        </div>
      ))}
    </div>
  );
};
