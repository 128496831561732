import React from 'react';
import { NavLink } from 'react-router-dom';

export default () => {
  const links = [
    { to: '/', content: '127.0.0.1' },
    { to: '/education', content: 'Education' },
    { to: '/experience', content: 'Experience' },
    //{ to: '/projects', content: 'Projects' }
  ];

  return (
    <div className='row'>
      <div className='col-12'>
        <ul className='nav nav-tabs header-tabs'>
          {links.map((link, index) => (
            <li className='nav-item' key={index}>
              <NavLink
                to={link.to}
                className='nav-link text-center'
                activeClassName='active'
                exact
              >
                {link.content}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
