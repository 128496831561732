import React from "react";
import schools from "../data/Schools";
import School from "./School";

export default () => {
  return (
    <div className="education">
      {schools.map((school, index) => (
        <React.Fragment key={index}>
          <School school={school} />
          {index !== schools.length - 1 ? <hr /> : null}
        </React.Fragment>
      ))}
    </div>
  );
};
