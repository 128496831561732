import React from "react";

const SchoolDetails = props => {
  return (
    <div className="school-details row">
      <div className="col">
        <p className="font-weight-bold mb-0">{props.name}</p>
        <p>
          {props.degree}{" "}
          <span className="font-weight-bold">
            {props.gpa ? props.gpa.toFixed(2) : null}
          </span>
        </p>
      </div>
      <div className="col-auto">
        <em className="text-muted d-block">{props.location}</em>
        <em className="text-muted">{props.dates}</em>
      </div>
    </div>
  );
};

export default props => {
  const { school } = props;

  return (
    <div className="school">
      <SchoolDetails
        name={school.name}
        location={school.location}
        degree={school.degree}
        gpa={school.gpa}
        dates={school.dates}
      />
      <p>
        {school.achievements.map(
          (achievement, index) =>
            achievement +
            (index === school.achievements.length - 1 ? "." : ", ")
        )}
      </p>
    </div>
  );
};
